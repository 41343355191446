import { MDBContainer } from "mdb-react-ui-kit";
import BillingAddress from "./components/checkout/BillingAddress";
import ShippingAddress from "./components/checkout/ShippingAddress";
import PaymentMethod from "./components/checkout/PaymentMethod";
import ImageUpload from "./components/checkout/ImageUpload";
import { useCartContext } from "./context/cartcontext";
import { useOrderContext } from "./context/ordercontext";
import axios from "axios";
import { useState, useEffect } from "react";
import SideCart from "./components/checkout/SideCart";

const Checkout = () => {
  const { total_price, shipping_fee } = useCartContext();
  const { state, cartDetails } = useOrderContext();
  const [orderId, setOrderId] = useState(null);

  const onlinePayment = async () => {
    const order = await axios({
      method: "post",
      url: `${process.env.REACT_APP_HYPERTEXT}://${process.env.REACT_APP_BACKEND_URL}/api/payment/createorder`,
      data: {
        amount: total_price + shipping_fee,
      },
      headers: {
        "Content-Type": "application/json",
      },
    });

    const { id: order_id, amount } = order.data;

    const options = {
      key: "rzp_test_dKhJ2jiI5qasOl", // Enter the Key ID generated from the Dashboard
      amount: amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: `${process.env.REACT_APP_COMPANY_NAME}`,
      description: "Test Transaction",
      image: `${process.env.REACT_APP_COMPANY_LOGO}`,
      order_id: order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      callback_url: `${process.env.REACT_APP_HYPERTEXT}://${process.env.REACT_APP_BACKEND_URL}/api/payment/success/${orderId}`,
      // callback_url: "http://localhost:3000/try",
      prefill: {
        name: `${state.billingInfo.firstName} ${state.billingInfo.lastName}`,
        email: `${state.billingInfo.email}`,
        contact: `${state.billingInfo.phone}`,
      },
      notes: {
        address: "Razorpay Corporate Office",
        cart: JSON.stringify(cartDetails),
      },
      theme: {
        color: "#3399cc",
      },
    };
    console.log(options);
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const offlinePayment = () => {
    axios
      .post(
        `${process.env.REACT_APP_HYPERTEXT}://${process.env.REACT_APP_BACKEND_URL}/api/refferal/updateCredits/${orderId}`
      )
      .then((response) => {
        if (response.status === 200) {
          console.log("Referral updated successfully:", response.data);
          // Navigate to the success page after a successful API call
          window.location.href = "/success";
        } else {
          console.log("Unexpected response status:", response.status);
        }
      })
      .catch((error) => {
        console.error(
          "Error calling referral update API:",
          error.response ? error.response.data : error.message
        );
      });
  };

  const placeOrderMongo = async (paymentStatus) => {
    axios
      .post(
        `${process.env.REACT_APP_HYPERTEXT}://${process.env.REACT_APP_BACKEND_URL}/api/order/placeOrder`,
        {
          ...state,
          paymentStatus: paymentStatus,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        console.log("result of the order placed ---> ", res);
        setOrderId(res.data.orderId);
        localStorage.setItem("orderId", res.data.orderId); //updating prevOrder details with state having all correct values

        return res.data;
        // localStorage.setItem("prevOrderDetails", JSON.stringify(state)); //updating prevOrder details with state having all correct values
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();

    // localStorage.setItem("prevOrderDetails", JSON.stringify(state));
    try {
      const paymentStatus =
        state.paymentDetails.modeOfPayment === "COD" ? "Success" : "Pending";

      const res = await placeOrderMongo(paymentStatus);
      console.log("placed order -->", res);
    } catch (error) {
      console.error("Error", error);
      throw error;
    }
  };
  useEffect(() => {
    if (orderId) {
      // Call the appropriate payment function based on the mode of payment

      console.log("localstorage --> ", localStorage.getItem("orderId"));
      if (state.paymentDetails.modeOfPayment === "COD") {
        offlinePayment();
      } else {
        onlinePayment(); // Assuming you have an onlinePayment function
      }
    } else {
      console.error("Failed to place order, no orderId returned.. ");
    }
  }, [orderId]);

  return (
    <>
      <MDBContainer className="my-5 py-5" style={{ maxWidth: "1100px" }}>
        <SideCart />
        <form onSubmit={HandleSubmit}>
          <ImageUpload />
          <BillingAddress />
          <ShippingAddress />
          <PaymentMethod />
          <div className="text-center">
            <button
              type="Submit"
              className="btn btn-success btn-block mx-auto"
              style={{
                height: "50px",
                fontSize: "16px",
                borderRadius: "10px",
                width: "50%",
              }}
            >
              Place order
            </button>
          </div>
        </form>
      </MDBContainer>
    </>
  );
};

export default Checkout;
