import { MDBContainer } from "mdb-react-ui-kit";
import BillingAddress from "./components/checkout/BillingAddress";
import ShippingAddress from "./components/checkout/ShippingAddress";
import PaymentMethod from "./components/checkout/PaymentMethod";
import ImageUpload from "./components/checkout/ImageUpload";
import { useCartContext } from "./context/cartcontext";
import { useOrderContext } from "./context/ordercontext";
import axios from "axios";
import { useState, useEffect } from "react";
import SideCart from "./components/checkout/SideCart";

const OrderByPresc = () => {
  const { total_price, shipping_fee } = useCartContext();
  const { state, cartDetails } = useOrderContext();
  const [orderId, setOrderId] = useState(null);

  const offlinePayment = () => {
    axios
      .post(
        `${process.env.REACT_APP_HYPERTEXT}://${process.env.REACT_APP_BACKEND_URL}/api/refferal/updateCredits/${orderId}`
      )
      .then((response) => {
        if (response.status === 200) {
          console.log("Referral updated successfully:", response.data);
          // Navigate to the success page after a successful API call
          window.location.href = "/success";
        } else {
          console.log("Unexpected response status:", response.status);
        }
      })
      .catch((error) => {
        console.error(
          "Error calling referral update API:",
          error.response ? error.response.data : error.message
        );
      });
  };

  const placeOrderMongo = async (paymentStatus) => {
    axios
      .post(
        `${process.env.REACT_APP_HYPERTEXT}://${process.env.REACT_APP_BACKEND_URL}/api/order/placeOrder`,
        {
          ...state,
          paymentStatus: paymentStatus,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("authToken"),
          },
        }
      )
      .then((res) => {
        console.log("result of the order placed ---> ", res);
        setOrderId(res.data.orderId);
        localStorage.setItem("orderId", res.data.orderId); //updating prevOrder details with state having all correct values

        return res.data;
        // localStorage.setItem("prevOrderDetails", JSON.stringify(state)); //updating prevOrder details with state having all correct values
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();

    // localStorage.setItem("prevOrderDetails", JSON.stringify(state));
    try {
      const paymentStatus =
        state.paymentDetails.modeOfPayment === "COD" ? "Success" : "Pending";

      const res = await placeOrderMongo(paymentStatus);
      console.log("placed order -->", res);
    } catch (error) {
      console.error("Error", error);
      throw error;
    }
  };
  useEffect(() => {
    if (orderId) {
      if (state.paymentDetails.modeOfPayment === "COD") {
        offlinePayment();
      }
    } else {
      console.error("Failed to place order, no orderId returned.. ");
    }
  }, [orderId]);

  return (
    <>
      <MDBContainer className="my-5 py-5" style={{ maxWidth: "1100px" }}>
        <h4
          style={{
            fontSize: "34px",
            fontWeight: 700,
            textTransform: "capitalize",
            padding: "15px 0",
          }}
        >
          Order through prescription
        </h4>
        <h6>*After analyzing prescription we will contact you </h6>
        <form onSubmit={HandleSubmit}>
          <ImageUpload />
          <BillingAddress />
          <ShippingAddress />
          <PaymentMethod />
          <div className="text-center">
            <button
              type="Submit"
              className="btn btn-success btn-block mx-auto"
              style={{
                height: "50px",
                fontSize: "16px",
                borderRadius: "10px",
                width: "50%",
              }}
            >
              Place order via COD
            </button>
          </div>
        </form>
      </MDBContainer>
    </>
  );
};

export default OrderByPresc;
