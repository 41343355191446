import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../styles/Button";

const HeroSection = ({ myData }) => {
  const { name } = myData;
  return (
    <Wrapper>
      <div className="container">
        <div className="grid grid-two-column">
          <div className="hero-section-data">
            <p
              className="intro-data"
              style={{
                color: "#ee4f10",
              }}
            >
              Welcome to
            </p>
            <h1>{name}</h1>
            <p>
              Welcome to {name}, your trusted online pharmacy and healthcare
              partner. Whether you need prescription medications,
              over-the-counter remedies, wellness products, or healthcare
              essentials, we have everything you need at competitive prices with
              fast and reliable delivery. Explore our extensive range of
              products, from trusted brands to innovative health solutions, and
              take charge of your well-being. At {name}, we believe that your
              health is your most valuable asset.
            </p>
            <NavLink to="/">
              <Button>Shop Now</Button>
            </NavLink>
          </div>
          <div className="hero-section-image">
            <figure>
              <img
                src="images/medicine_card.jpg"
                alt="medicine"
                className="img-style"
              ></img>
            </figure>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 12rem 0;
  img {
    min-width: 10rem;
    height: 10rem;
  }
  .hero-section-data {
    p {
      margin: 2rem 0;
    }
    h1 {
      text-transform: capitalize;
      font-weight: bold;
    }
    .intro-data {
      margin-bottom: 0;
    }
  }
  .hero-section-image {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  figure {
    position: relative;
    &::after {
      content: "";
      width: 60%;
      height: 80%;
      background-color: rgba(200, 80, 10, 0.4);
      position: absolute;
      left: 50%;
      top: -5rem;
      z-index: -1;
    }
  }
  .img-style {
    width: 100%;
    height: auto;
  }
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .grid {
      gap: 10rem;
    }
    figure::after {
      content: "";
      width: 50%;
      height: 100%;
      left: 0;
      top: 10%;
      /* bottom: 10%; */
      background-color: rgba(200, 80, 10, 0.4);
    }
  }
`;

export { HeroSection };
