import React from "react";
import Services from "./components/home/Services";
import FeatureProduct from "./components/home/FeatureProduct";
import FAQs from "./components/home/FAQs";
import Carousel from "./components/home/Carousel";
import FilterSection from "./components/filter/FilterSection";
import { useFilterContext } from "./context/filtercontext";
import "./styles/Home.css";
import Sort from "./components/filter/Sort";
import ProductList from "./components/product/ProductList";
import Loader from "./components/Loader";

const Home = () => {
  const {
    filters: { text, category },
    loading,
  } = useFilterContext();

  return (
    <>
      <FilterSection />
      {text !== "" ? (
        <div className="container product-view--sort">
          {loading ? (
            <div>
              <Loader />
            </div>
          ) : (
            <>
              <div className="sort-filter">
                <Sort />
              </div>
              <div className="main-product">
                <ProductList />
              </div>
            </>
          )}
        </div>
      ) : (
        <>
          <Carousel />
          <FeatureProduct />
          <FAQs />
          <Services />
        </>
      )}
    </>
  );
};

export default Home;
