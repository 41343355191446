import React from "react";
import "./styles/Privacypolicy.css";
import { MDBIcon } from "mdb-react-ui-kit";
import imgText from "./utils/shipmentImg.jpg";
import HeroSection from "./styles/HeroSection";

const PrivacyPolicy = () => {
  return (
    <div>
      <HeroSection page="Privacy Policy" />
      {/* <div className="Contact">
        <div className="ContactText">
          <h2>Return Policy</h2>
        </div>
      </div> */}
      <div className="lastSection">
        {/* <span className="shipmentLogo">
          <MDBIcon fas icon="truck" />
        </span> */}
        {/* <div className="ReturnPolicy"> */}

        <div className="privacyText">
          <h2>
            At {process.env.REACT_APP_COMPANY_NAME} we are committed to
            protecting your privacy.
          </h2>
          <p>
            This Privacy Policy outlines how we collect, use, and disclose
            information about our users.
          </p>
          <br />
          <br />
          <h3>1. Information We Collect</h3>
          <p>
            We may collect the following types of information when you visit our
            website or use our services:
          </p>
          <ul>
            <li>
              <span>&#8226; </span>
              <strong>Personal Information:</strong> Name, email address,
              shipping address, phone number, payment information (collected
              securely through our payment processor).
            </li>
            <li>
              <span>&#8226; </span>
              <strong>Non-Personal Information:</strong> Browser type, language
              preference, referring site, and the date and time of each visitor
              request.
            </li>
          </ul>

          <h3>2. How We Use Your Information</h3>

          <p>We may use the information we collect in the following ways:</p>
          <ul>
            <li>
              <span>&#8226; </span>To process and fulfill your orders.
            </li>
            <li>
              <span>&#8226; </span>To communicate with you about your orders,
              account, or inquiries.
            </li>
            <li>
              <span>&#8226; </span>To improve our website and services based on
              your feedback.
            </li>
            <li>
              <span>&#8226; </span>To send promotional emails or newsletters
              (you may opt-out at any time).
            </li>
            <li>
              <span>&#8226; </span>To prevent fraud and maintain the security of
              our website.
            </li>
          </ul>

          <h3>3. Information Sharing</h3>
          <ul>
            <p>
              We do not sell, trade, or otherwise transfer to outside parties
              your personally identifiable information. This does not include
              trusted third parties who assist us in operating our website,
              conducting our business, or servicing you, so long as those
              parties agree to keep this information confidential.
            </p>
          </ul>

          <h3>4. Data Security</h3>
          <ul>
            <p>
              We implement a variety of security measures to maintain the safety
              of your personal information when you place an order or enter,
              submit, or access your personal information.
            </p>
          </ul>

          <h3>5. Your Choices</h3>
          <ul>
            <p>
              You can always choose not to provide certain information, but it
              may limit your ability to take advantage of some features of our
              website.
            </p>
          </ul>

          <div className="disclaimer">
            <p>
              <strong>Note:</strong> This privacy policy is subject to change
              without prior notice. Please refer to our website for the most
              current policy. Thank you for choosing{" "}
              <strong>{process.env.REACT_APP_COMPANY_NAME}</strong>. We
              appreciate your business and look forward to serving you again.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
