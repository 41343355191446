const FAQs = () => {
  return (
    <div className="FAQs">
      <section>
        <h3 class="text-center mb-4 pb-2 fw-bold">FAQ</h3>
        <p class="text-center mb-5">
          Find the answers for the most frequently asked questions below
        </p>

        <div class="row">
          <div class="col-md-6 col-lg-4 mb-4">
            <h6 class="mb-3 ">
              <i class="far fa-paper-plane pe-2"></i> Are my payment Info safe?
            </h6>
            <p>
              <strong>
                <u>Absolutely!</u>
              </strong>{" "}
              We work with top payment companies which guarantees your safety
              and security. All billing information is stored on our payment
              processing partner.
            </p>
          </div>

          <div class="col-md-6 col-lg-4 mb-4">
            <h6 class="mb-3 ">
              <i class="fas fa-pen-alt pe-2"></i> Is buying medicine online is
              safe?
            </h6>
            <p>
              <strong>
                <u>Yes, Absolutely!</u>
              </strong>{" "}
              Absolutely! All our medicines undergo a three-step quality check
              process to ensure they are of high quality. We source our products
              only from licensed retail pharmacies.
            </p>
          </div>

          <div class="col-md-6 col-lg-4 mb-4">
            <h6 class="mb-3">
              <i class="fas fa-user pe-2"></i> Why choose us for your medicine
              home delivery?
            </h6>
            <p>
              <ul>
                <li>
                  <span>&#8226; </span>We have high Quality check
                </li>
                <li>
                  <span>&#8226; </span>Delivery in 24-48 hrs.
                </li>
                <li>
                  <span>&#8226; </span>Attractive discounts
                </li>
                <li>
                  <span>&#8226; </span>Cash On Delivery available.
                </li>
              </ul>
            </p>
          </div>

          <div class="col-md-6 col-lg-4 mb-4">
            <h6 class="mb-3">
              <i class="fas fa-rocket pe-2"></i> When will{" "}
              {process.env.REACT_APP_COMPANY_NAME} deliver my medicines?
            </h6>
            <p>
              Once you purchase your medicines online with us, you will get it
              within 24-48* hours. <br />
              *T&C: The delivery time might vary depending on the delivery
              location.
            </p>
          </div>

          <div class="col-md-6 col-lg-4 mb-4">
            <h6 class="mb-3">
              <i class="fas fa-home pe-2"></i> How do i cancel my order?
            </h6>
            <p>
              You can cancel your order by contacting us from the information
              provided in <a href="/contact">contact page</a>.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FAQs;
