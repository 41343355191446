import React from "react";
import styled from "styled-components";
import { Button } from "../../styles/Button";
import FormatPrice from "../../utils/FormatPrice";
import imagePath from "../../utils/medicine.jpg";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import Pagenation from "../product/Pagenation";
import { ToastContainer, toast } from "react-toastify";
import { useCartContext } from "../../context/cartcontext";
// import { Button } from "../../styles/Button";

const ListView = ({ products }) => {
  const { addToCart } = useCartContext();

  const handleOnClick = async (_id, name, price) => {
    if (localStorage.getItem("authToken") === null) {
      // navigate("/login");
      toast.error("Please login ");
    } else {
      addToCart(_id, 1, { _id, name, price, stock: 5 });
      toast.success("Product Added to Cart");
    }
  };

  return (
    <>
      {/* {!loading?<div>laoding</div>: */}
      <Wrapper className="section">
        <ToastContainer />
        <div className="container-grid">
          {products.map((curElem) => {
            const { _id, name, company, price, pack, code } = curElem;

            return (
              <div className="card grid grid-two-column">
                <figure>
                  <img src={imagePath} alt="medicine" />
                </figure>
                <div className="card-data">
                  <NavLink to={`/singleproduct/${_id}`}>
                    <h3>{name}</h3>
                    <p>Company Name: {company}</p>
                    {/* <p>Code: {code}</p>
                <p>Pack: {pack}</p> */}
                    <p>
                      Price: <FormatPrice price={price} />
                    </p>
                  </NavLink>

                  {/* <NavLink to={`/singleproduct/${_id}`} className="btn-main">
                    <Button className="btn">Read More</Button>
                  </NavLink> */}
                  <Button
                    onClick={() => {
                      handleOnClick(_id, name, price);
                    }}
                    className="btn-main"
                    style={{ fontSize: "0.9rem" }}
                  >
                    Add To Cart
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.section`
  // padding: 1rem 0;
  .container {
    // max-width: 120rem;
    max-width: 60%;
  }
  .grid {
    gap: 3.2rem;
  }
  figure {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    transition: all 0.5s linear;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      transition: all 0.2s linear;
      cursor: pointer;
    }
    &:hover::after {
      width: 100%;
    }
    &:hover img {
      transform: scale(1.2);
    }
    img {
      max-width: 90%;
      margin-top: 1.5rem;
      height: 20rem;
      transition: all 0.2s linear;
    }
  }
  .card {
    border: 0.1rem solid rgb(170 170 170 / 40%);
    margin: 2rem;
    .card-data {
      padding: 0 2rem;
    }
    h3 {
      margin: 2rem 0;
      font-weight: 300;
      font-size: 2.4rem;
      text-transform: capitalize;
    }
    .btn {
      margin: 2rem 0;
      background-color: rgb(0 0 0 / 0%);
      border: 0.1rem solid rgb(98 84 243);
      display: flex;
      justify-content: center;
      align-items: center;
      color: rgb(98 84 243);
      &:hover {
        background-color: rgb(98 84 243);
      }
      &:hover a {
        color: #fff;
      }
      a {
        color: rgb(98 84 243);
        font-size: 1.4rem;
      }
    }

    .btn-main {
      border-radius: 0.2rem;
      margin-bottom: 1rem; /* Adjust the value as needed */
    }

    .btn-main .btn:hover {
      color: #fff;
    }
  }
`;

export default ListView;
