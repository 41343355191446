import React from "react";
import "./styles/Termsandcondition.css";
import { MDBIcon } from "mdb-react-ui-kit";
import imgText from "./utils/shipmentImg.jpg";
import HeroSection from "./styles/HeroSection";

const TermsAndConditions = () => {
  return (
    <div>
      <HeroSection page="Terms And Conditions" />
      {/* <div className="Contact">
        <div className="ContactText">
          <h2>Return Policy</h2>
        </div>
      </div> */}
      <div className="lastSection">
        {/* <span className="shipmentLogo">
          <MDBIcon fas icon="truck" />
        </span> */}
        {/* <div className="ReturnPolicy"> */}

        <div className="terms-conditions">
          <h2>Terms and Conditions</h2>

          <p>
            Welcome to {process.env.REACT_APP_COMPANY_NAME}. These Terms and
            Conditions outline the rules and regulations for the use of our
            website and services.
          </p>

          <h3>1. Acceptance of Terms</h3>
          <p>
            By accessing or using our website, you agree to be bound by these
            Terms and Conditions and our Privacy Policy. If you do not agree to
            these terms, please do not use our website.
          </p>

          <h3>2. Changes to Terms</h3>
          <p>
            We reserve the right to update or modify these Terms and Conditions
            at any time. Any changes will be effective immediately upon posting
            on our website. Your continued use of the website constitutes your
            acceptance of the revised terms.
          </p>

          <h3>3. Use of Website</h3>
          <p>
            You agree to use our website only for lawful purposes and in a
            manner that does not infringe on the rights of, or restrict or
            inhibit anyone else's use and enjoyment of, the website.
          </p>

          <h3>4. Account Responsibilities</h3>
          <p>
            If you create an account on our website, you are responsible for
            maintaining the confidentiality of your account information and for
            all activities that occur under your account. You agree to notify us
            immediately of any unauthorized use of your account or any other
            breach of security.
          </p>

          <h3>5. Product Information</h3>
          <p>
            We strive to provide accurate and up-to-date information about our
            products. However, we do not warrant that the information is always
            accurate, complete, or current. We reserve the right to correct any
            errors or inaccuracies and to update information at any time without
            prior notice.
          </p>

          <h3>6. Orders and Payments</h3>
          <p>
            All orders are subject to acceptance and availability. We reserve
            the right to refuse or cancel any order for any reason. Payments
            must be made through our designated payment methods, and you agree
            to provide accurate and complete payment information.
          </p>

          <h3>7. Intellectual Property</h3>
          <p>
            All content on our website, including text, graphics, logos, and
            images, is the property of {process.env.REACT_APP_COMPANY_NAME} or
            its licensors and is protected by copyright, trademark, and other
            intellectual property laws. You may not use, reproduce, or
            distribute any content from our website without our express written
            permission.
          </p>

          <h3>8. Limitation of Liability</h3>
          <p>
            To the fullest extent permitted by law,{" "}
            {process.env.REACT_APP_COMPANY_NAME} will not be liable for any
            indirect, incidental, special, consequential, or punitive damages
            arising from or related to your use of the website or the products
            purchased.
          </p>

          <h3>9. Governing Law</h3>
          <p>
            These Terms and Conditions are governed by and construed in
            accordance with the laws of India. Any disputes arising out of or
            related to these terms will be resolved in the courts located within
            Delhi, India.
          </p>
          <br />
          <p>
            Thank you for visiting {process.env.REACT_APP_COMPANY_NAME}. We
            appreciate your business and look forward to serving you.
          </p>
        </div>
        {/* </div> */}
        {/* <div
          className="textImage"
          style={{ borderLeft: "15px solid #ff7f23", paddingLeft: "10px" }}
        >
          <img src={imgText} alt="contactImg" />
        </div> */}
      </div>
    </div>
  );
};

export default TermsAndConditions;
