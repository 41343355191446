import React, { useState } from "react";
import { useUserContext } from "./context/usercontext";
import { useCartContext } from "./context/cartcontext";
import { useNavigate } from "react-router-dom";
import HeroSection from "./styles/HeroSection";
import "./styles/Login.css";
import { ToastContainer, toast } from "react-toastify";
import OtpLogin from "./OtpLogin";

const PartnerLogin = () => {
  const {
    login,
    signup,
    onChangeLogin,
    onChangeSignup,
    SubmitLogin,
    SubmitSignUpAsPartner,
  } = useUserContext();
  const { updateCartFromStorage } = useCartContext();

  const navigate = useNavigate(); //to redirect to other page
  // const [otpMatched, setOtpMatched] = useState(false);

  const handleSubmitSignUp = async (e) => {
    e.preventDefault();

    const passwordInput = document.getElementById("form7");
    const passwordValue = passwordInput.value.toString();

    if (signup.password !== passwordValue) {
      toast.error("Password didn't matched.");
      return;
    }

    // if (!otpMatched) {
    //   toast.error("OTP doesn't match");
    //   return;
    // }

    try {
      await SubmitSignUpAsPartner();
      updateCartFromStorage();
      navigate("/"); //redirecitng person to cart
    } catch (error) {
      if (error.response.status == 400) {
        toast.error(error.response.data.error);
      }
      console.log(error);
      // window.location.reload();
      // toast.error(error.response.data.error);
    }
  };

  const isValidEmail = (email) => {
    // Regular expression to match email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div className="login">
      {/* <div className="container p-3 my-5 d-flex flex-column w-50 text"> */}
      <HeroSection page="Sign Up As Partner" />
      <ToastContainer />
      <div className="main">
        <div className="sub-main">
          <div className="tab-content">
            <div className={`tab-pane show active`}>
              <form onSubmit={handleSubmitSignUp} className="loginForm">
                <div className="text-center mt-5 mb-3">
                  <p>SIGN UP As Partner</p>
                </div>

                <div className="mb-4 fields">
                  <input
                    placeholder="Name"
                    type="text"
                    id="form3"
                    onChange={onChangeSignup}
                    value={signup.name}
                    name="name"
                  />
                </div>

                <div className="mb-4 fields">
                  <input
                    placeholder="Email"
                    type="email"
                    id="form5"
                    onChange={onChangeSignup}
                    value={signup.email}
                    name="email"
                  />
                </div>

                {/* {signup.email && isValidEmail(signup.email) && (
                  <OtpLogin setOtpMatched={setOtpMatched} />
                )} */}

                <div className="mb-4 fields">
                  <input
                    placeholder="Password"
                    type="password"
                    id="form6"
                    onChange={onChangeSignup}
                    value={signup.password}
                    name="password"
                  />
                </div>

                <div className="mb-4 fields">
                  <input
                    placeholder="Repeat Your Password"
                    type="password"
                    id="form7"
                    name="password"
                  />
                </div>

                <button type="submit" style={{ width: "80%" }}>
                  <strong>Sign up</strong>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerLogin;
