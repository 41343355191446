import React from "react";
import styled from "styled-components";
import FilterSection from "./components/filter/FilterSection";
import Sort from "./components/filter/Sort";
import ProductList from "./components/product/ProductList";

const Products = () => {
  return (
    <Wrapper>
      {/* <div className="container grid grid-filter-column"> */}
      {/* <div className="container">
          <FilterSection />
        </div> */}

      <div className="container product-view--sort">
        <div className="sort-filter">
          <Sort />
        </div>
        <div className="main-product">
          <ProductList />
        </div>
      </div>

      {/* </div> */}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .grid-filter-column {
    grid-template-columns: 0.2fr 1fr;
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .grid-filter-column {
      grid-template-columns: 1fr;
    }
  }
`;

export default Products;
