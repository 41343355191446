import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";
import carousel1 from "../../utils/Carousel1.png";
import carousel2 from "../../utils/Carousel2.png";
import carousel3 from "../../utils/Carousel3.png";

const Carousel = () => {
  return (
    <div className="carousel-container">
      <MDBCarousel showIndicators showControls fade className="carousel">
        <MDBCarouselItem
          className="w-100 d-block"
          itemId={1}
          src={carousel1}
          alt="..."
        ></MDBCarouselItem>

        <MDBCarouselItem
          className="w-100 d-block"
          itemId={2}
          src={carousel2}
          alt="..."
        ></MDBCarouselItem>

        <MDBCarouselItem
          className="w-100 d-block"
          itemId={3}
          src={carousel3}
          alt="..."
        ></MDBCarouselItem>
      </MDBCarousel>
    </div>
  );
};

export default Carousel;
