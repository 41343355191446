import React from "react";
import { QRCodeCanvas } from "qrcode.react";

const QRcode = ({ referralCode }) => {
  const encodedReferralCode = btoa(referralCode); // Base64 encode the referral code
  const registrationUrl = `${process.env.REACT_APP_COMPANY_SITE}/login?code=${encodedReferralCode}`;

  return (
    <div>
      <h4>Referral QRCode</h4>
      <QRCodeCanvas value={registrationUrl} />
    </div>
  );
};

export default QRcode;
